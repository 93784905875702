/* eslint-disable react/display-name */
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import Persist from '@egjs/persist';
import { Button, Input, Row, Select, Spin } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import dayjs from 'dayjs';
import { useUser } from 'next-firebase-auth';
import ReactQuill from 'react-quill';
import CustomToolbarTab from '@/components/ckk/CookieToolbarTab';
import Img from '@/components/common/Img';
import WrappedEditor from '@/components/WrappedEditor';
import { Article, BaseArticle, BaseCookie, LocalizedString, Topping, Treasure } from '@/types/ckk';
import { DayJsDateFormat } from '@/types/dayjs';
import { getIsAdmin } from '@/utils/admin';
import { getValuesFromEnum } from '@/utils/enum';
import { createArticle, updateArticle } from '@/utils/firebase/article';
const {
  Option
} = Select;
enum CookieRunToolbarTab {
  COOKIE = 'COOKIE',
  TREASURE = 'TREASURE',
  TOPPING = 'TOPPING',
}
export enum ArticleCategory {
  NOTICE = 'NOTICE',
  EVENT = 'EVENT',
  GENERAL = 'GENERAL',
  QUESTION = 'QUESTION',
  GUIDE = 'GUIDE',
  INFO = 'INFO',
  FANART = 'FANART',
  KINGDOM_DECORATION = 'KINGDOM_DECORATION',
  FRIEND = 'FRIEND',
  GUILD = 'GUILD',
  PATCH = 'PATCH',
  PREVIEW = 'PREVIEW',
  VIDEO = 'VIDEO',
  FAN_FICTION = 'FAN_FICTION',
}
export type ArticleCategoryWithAll = ArticleCategory | 'ALL';
export const ARTICLE_CATEGORY_MAP: Record<ArticleCategory, {
  emoji: string;
  category: ArticleCategory;
  name: LocalizedString;
}> = {
  [ArticleCategory.NOTICE]: {
    category: ArticleCategory.NOTICE,
    emoji: '📣',
    name: {
      en: 'Notice',
      ko: '공지'
    }
  },
  [ArticleCategory.EVENT]: {
    category: ArticleCategory.EVENT,
    emoji: '🎉',
    name: {
      en: 'Event',
      ko: '이벤트'
    }
  },
  [ArticleCategory.GENERAL]: {
    category: ArticleCategory.GENERAL,
    emoji: '👑',
    name: {
      en: 'General',
      ko: '일반'
    }
  },
  [ArticleCategory.QUESTION]: {
    category: ArticleCategory.QUESTION,
    emoji: '🙋‍♂️',
    name: {
      en: 'Question',
      ko: '질문'
    }
  },
  [ArticleCategory.GUIDE]: {
    category: ArticleCategory.GUIDE,
    emoji: '✍️',
    name: {
      en: 'Guide / Tip',
      ko: '공략 & 팁'
    }
  },
  [ArticleCategory.FRIEND]: {
    category: ArticleCategory.FRIEND,
    emoji: '🤝',
    name: {
      en: 'Friend',
      ko: '친구'
    }
  },
  [ArticleCategory.GUILD]: {
    category: ArticleCategory.GUILD,
    emoji: '🔥',
    name: {
      en: 'Guild',
      ko: '길드'
    }
  },
  [ArticleCategory.INFO]: {
    category: ArticleCategory.INFO,
    emoji: '💡',
    name: {
      en: 'Info',
      ko: '정보'
    }
  },
  [ArticleCategory.FANART]: {
    category: ArticleCategory.FANART,
    emoji: '🎨',
    name: {
      en: 'Fan Art',
      ko: '팬아트'
    }
  },
  [ArticleCategory.FAN_FICTION]: {
    category: ArticleCategory.FANART,
    emoji: '📖',
    name: {
      en: 'Fan Fiction',
      ko: '팬픽'
    }
  },
  [ArticleCategory.KINGDOM_DECORATION]: {
    category: ArticleCategory.KINGDOM_DECORATION,
    emoji: '🏗️',
    name: {
      en: 'Kingdom Decoration',
      ko: '왕국 꾸미기'
    }
  },
  [ArticleCategory.PATCH]: {
    category: ArticleCategory.PATCH,
    emoji: '⚙️',
    name: {
      en: 'Patch Note',
      ko: '패치노트'
    }
  },
  [ArticleCategory.PREVIEW]: {
    category: ArticleCategory.PREVIEW,
    emoji: '📚',
    name: {
      en: 'CM Note',
      ko: 'CM노트'
    }
  },
  [ArticleCategory.VIDEO]: {
    category: ArticleCategory.VIDEO,
    emoji: '🎥',
    name: {
      en: 'Video',
      ko: '영상'
    }
  }
};
interface Props {
  isEdit: boolean;
  article?: Article;
  cookies: BaseCookie[];
  treasures: Treasure[];
  toppings: Topping[];
}
const blind = true;
function WriteBoard({
  isEdit,
  article,
  cookies,
  treasures,
  toppings
}: Props) {
  const {
    locale,
    push
  } = useRouter();
  const [content, setContent] = useState<string>(isEdit ? article.content[locale] : '');
  const quillRef = useRef<ReactQuill>();
  const [title, setTitle] = useState<string>(isEdit ? article.title[locale] : '');
  const [category, setCategory] = useState<ArticleCategory>(isEdit ? article.category : ArticleCategory.GENERAL);
  const [cookieImageType, setCookieImageType] = useState<'icon' | 'card' | 'animation'>('card');
  const [cookieRunToolbarTab, setCookieRunToolbarTab] = useState<CookieRunToolbarTab>(CookieRunToolbarTab.COOKIE);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>(article?.images ?? []);
  const [thumbnailImage, setThumbnailImage] = useState(article?.thumbnailImage);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  useEffect(() => {
    if (images.length === 0) {
      setThumbnailImage(null);
    }
    if (images.length > 0 && !images.includes(thumbnailImage)) {
      setThumbnailImage(images[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);
  const user = useUser();
  const isAdmin = getIsAdmin(user.firebaseUser);
  const handleOnChangeCategory = (nextCategory: ArticleCategory) => {
    setCategory(nextCategory);
  };
  const handleCookieClick = (cookieId: string) => () => {
    const quill = quillRef?.current?.getEditor();
    if (quill) {
      const cursorPosition = quill.getSelection().index;
      const cookie = cookies.find(_cookie => _cookie.id === cookieId);
      const cookieString = cookie.name[locale];
      quill.insertText(cursorPosition, cookieString);
    }
  };
  const handleTreasureClick = (treasureId: string) => () => {
    const quill = quillRef?.current?.getEditor();
    if (quill) {
      const cursorPosition = quill.getSelection().index;
      const cookie = treasures.find(_treasure => _treasure.id === treasureId);
      const cookieString = cookie.name[locale];
      quill.insertText(cursorPosition, cookieString);
    }
  };
  const handleToppingClick = (toppingId: string) => () => {
    const quill = quillRef?.current?.getEditor();
    if (quill) {
      const cursorPosition = quill.getSelection().index;
      const cookie = toppings.find(_topping => _topping.id === toppingId);
      const cookieString = cookie.name[locale];
      quill.insertText(cursorPosition, cookieString);
    }
  };
  const register = async () => {
    setIsSubmitting(true);
    if (title.trim() === '') {
      alert('제목을 입력해주세요.');
      setIsSubmitting(false);
      return;
    }
    if (content.trim() === '') {
      alert('내용을 입력해주세요.');
      setIsSubmitting(false);
      return;
    }
    const doc: Omit<BaseArticle, 'id'> = {
      title: {
        ko: locale === 'ko' ? title : '',
        en: locale === 'en' ? title : ''
      },
      content: {
        ko: locale === 'ko' ? content : '',
        en: locale === 'en' ? content : ''
      },
      category,
      author: {
        uid: isEdit ? article.author.uid : user.firebaseUser?.uid
      },
      createdAt: (isEdit ? dayjs(article.createdAt) : dayjs()).format(DayJsDateFormat.YMDHms),
      images,
      thumbnailImage,
      updatedAt: dayjs().format(DayJsDateFormat.YMDHms),
      readCount: 0
    };
    createArticle({
      doc,
      withNotification: false
    }).then(id => {
      const articlePersist = new Persist('ARTICLE');
      articlePersist.set('LIST', null);
      push(`/ckk/community/free_board/detail/${id}`);
    });
  };
  const edit = async () => {
    setIsSubmitting(true);
    if (title.trim() === '') {
      alert('제목을 입력해주세요.');
      setIsSubmitting(false);
      return;
    }
    if (content.trim() === '') {
      alert('내용을 입력해주세요.');
      setIsSubmitting(false);
      return;
    }
    const doc: Omit<BaseArticle, 'id'> = {
      ...article,
      title: {
        ko: locale === 'ko' ? title : '',
        en: locale === 'en' ? title : ''
      },
      content: {
        ko: locale === 'ko' ? content : '',
        en: locale === 'en' ? content : ''
      },
      category,
      author: {
        uid: isEdit ? article.author.uid : user.firebaseUser?.uid
      },
      createdAt: (isEdit ? dayjs(article.createdAt) : dayjs()).format(DayJsDateFormat.YMDHms),
      images,
      thumbnailImage,
      updatedAt: dayjs().format(DayJsDateFormat.YMDHms)
    };
    updateArticle({
      id: article.id,
      doc,
      withNotification: false
    }).then(id => {
      const articlePersist = new Persist('ARTICLE');
      articlePersist.set('LIST', null);
      push(`/ckk/community/free_board/detail/${id}`);
    });
  };
  const renderRegisterButton = (size: SizeType = 'middle') => <Button className="!font-bold" disabled={isSubmitting} loading={isSubmitting} size={size} type="primary" onClick={() => {
    if (isEdit) {
      edit();
    } else {
      register();
    }
  }} data-sentry-element="Button" data-sentry-component="renderRegisterButton" data-sentry-source-file="WriteBoard.tsx">
      {isEdit ? '수정' : '등록'}
    </Button>;
  return <>
      <div className="sticky z-[4] flex h-12 w-full items-center justify-between bg-white px-4" style={{
      top: 49,
      zIndex: 4
    }}>
        <div>글 {isEdit ? '수정' : '작성'}하기</div>
        <div className="flex gap-2">
          <Button className="!font-bold" disabled={isSubmitting} loading={isSubmitting} onClick={() => {
          if (confirm('작성중입니다. 정말 취소하시겠어요?')) {
            push(`/ckk/community/free_board`);
          }
        }} data-sentry-element="Button" data-sentry-source-file="WriteBoard.tsx">
            취소
          </Button>
          {renderRegisterButton('small')}
        </div>
      </div>
      <div className="mt-3 !flex w-full flex-wrap gap-2 px-3">
        <Select<ArticleCategory> listHeight={480} placeholder={locale === 'ko' ? '카테고리를 선택해주세요.' : 'Select category'} size="middle" style={{
        width: '100%'
      }} value={category} onChange={handleOnChangeCategory} data-sentry-element="Select" data-sentry-source-file="WriteBoard.tsx">
          {getValuesFromEnum(ArticleCategory).filter(articleCategory => {
          if (!isAdmin) {
            return articleCategory !== ArticleCategory.NOTICE && articleCategory !== ArticleCategory.EVENT && articleCategory !== ArticleCategory.PATCH && articleCategory !== ArticleCategory.PREVIEW && articleCategory !== ArticleCategory.VIDEO;
          }
          return true;
        }).map((_category: ArticleCategory) => {
          return <Option key={_category} value={_category}>
                  {ARTICLE_CATEGORY_MAP[_category].emoji}{' '}
                  {ARTICLE_CATEGORY_MAP[_category].name[locale]}
                </Option>;
        })}
        </Select>
      </div>
      <div className="mb-2 w-full px-3 pt-2">
        <Input className="w-full !rounded-lg bg-white p-1 px-3" placeholder="제목" size="middle" title="title" value={title} onChange={e => {
        const {
          value: nextTitle
        } = e.target;
        setTitle(nextTitle);
      }} data-sentry-element="Input" data-sentry-source-file="WriteBoard.tsx" />
      </div>
      <Spin fullscreen size="large" spinning={isImageUploading} tip="이미지 업로드중..." data-sentry-element="Spin" data-sentry-source-file="WriteBoard.tsx" />
      <div className="w-full bg-white">
        <WrappedEditor content={content} id="editor" quillRef={quillRef} setContent={setContent} setImages={setImages} setIsImageUploading={setIsImageUploading} onChange={setContent} data-sentry-element="WrappedEditor" data-sentry-source-file="WriteBoard.tsx" />
        {!blind && <>
            <Row justify="start" style={{
          background: 'rgba(0,0,0,0.1)'
        }}>
              <CustomToolbarTab icon={<Img className="h-4 w-4 object-contain" src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/8893b523-b142-4caa-e41f-10444b5d1f00/publicIcon" />} selected={cookieRunToolbarTab === CookieRunToolbarTab.COOKIE} onClick={() => setCookieRunToolbarTab(CookieRunToolbarTab.COOKIE)}>
                쿠키
              </CustomToolbarTab>
              <CustomToolbarTab icon={<Img className="h-4 w-4 object-contain" src="https://i.ibb.co/vdtXrns/Treasure-old-pilgrims-scroll.png" />} selected={cookieRunToolbarTab === CookieRunToolbarTab.TREASURE} onClick={() => setCookieRunToolbarTab(CookieRunToolbarTab.TREASURE)}>
                보물
              </CustomToolbarTab>
              <CustomToolbarTab icon={<Img className="h-4 w-4 object-contain" src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/c7bd0b34-51dd-44dd-f0dc-3c7882fb4e00/publicIcon" />} selected={cookieRunToolbarTab === CookieRunToolbarTab.TOPPING} onClick={() => setCookieRunToolbarTab(CookieRunToolbarTab.TOPPING)}>
                토핑
              </CustomToolbarTab>
            </Row>
            <Row>
              {cookieRunToolbarTab === CookieRunToolbarTab.COOKIE && <>
                  <Select options={[{
              value: 'card',
              label: '카드'
            }, {
              value: 'icon',
              label: '아이콘'
            }, {
              value: 'stone',
              label: '영혼석'
            }, {
              value: 'animation',
              label: '애니메이션 (데이터 주의!)'
            }]} style={{
              width: '100%',
              margin: 5
            }} value={cookieImageType} onChange={value => setCookieImageType(value)} />
                  <div className="flex flex-wrap items-center justify-start gap-1 p-2">
                    {cookies.map(cookie => <Img key={cookie.name.en} alt="cookie" className="h-12 w-12" src={cookie.image[cookieImageType]} onClick={handleCookieClick(cookie.id)} />)}
                  </div>
                </>}
              {cookieRunToolbarTab === CookieRunToolbarTab.TREASURE && <div className="flex flex-wrap items-center justify-start gap-1 p-2">
                  {treasures.map(treasure => <Img key={treasure.name.en} alt="treasure" className="h-12 w-12" src={treasure.image} onClick={handleTreasureClick(treasure.id)} />)}
                </div>}
              {cookieRunToolbarTab === CookieRunToolbarTab.TOPPING && <div className="flex flex-wrap items-center justify-start gap-1 p-2">
                  {toppings.map(topping => <Img key={topping.name.en} alt="topping" className="h-12 w-12" src={topping.image} onClick={handleToppingClick(topping.id)} />)}
                </div>}
            </Row>
          </>}
      </div>
      <div className="my-4 !flex w-full justify-between gap-2 px-4">
        <Button className="!font-bold" disabled={isSubmitting} loading={isSubmitting} size="middle" onClick={() => {
        if (title.trim() === '' && content.trim() === '' || confirm('작성중입니다. 정말 취소하시겠어요?')) {
          push(`/ckk/community/free_board`);
        }
      }} data-sentry-element="Button" data-sentry-source-file="WriteBoard.tsx">
          취소
        </Button>
        {renderRegisterButton()}
      </div>
    </>;
}
export default WriteBoard;