import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import QuillNoSSRWrapper from '@/components/QuillNoSSRWrapper';
interface Props extends Omit<ReactQuillProps, 'value'> {
  quillRef: React.MutableRefObject<ReactQuill>;
  content: string;
  setContent: (prevContent: string) => void;
  setImages?: React.Dispatch<React.SetStateAction<string[]>>;
  setIsImageUploading: React.Dispatch<React.SetStateAction<boolean>>;
}
const CustomToolbar = () => <div className="sticky top-[97px] bg-white" id="toolbar" style={{
  zIndex: 2
}} data-sentry-component="CustomToolbar" data-sentry-source-file="WrappedEditor.tsx">
    <span className="ql-formats">
      <select className="ql-size">
        <option value="11px">11</option>
        <option selected value="13px">
          13
        </option>
        <option value="19px">19</option>
        <option value="28px">28</option>
        <option value="38px">38</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-blockquote"></button>
      <button className="ql-link"></button>
    </span>
    <span className="ql-formats">
      <select className="ql-color"></select>
      <select className="ql-background"></select>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <select className="ql-align"></select>
    </span>
    <span className="ql-formats">
      <button className="ql-image ql-icon"></button>
      <button className="ql-video ql-icon"></button>
    </span>
  </div>;
function WrappedEditor({
  quillRef,
  content,
  setContent,
  setImages,
  setIsImageUploading,
  ...props
}: Props) {
  return <>
      <CustomToolbar data-sentry-element="CustomToolbar" data-sentry-source-file="WrappedEditor.tsx" />
      <QuillNoSSRWrapper content={content} forwardedRef={quillRef} placeholder="내용을 입력해주세요." setContent={setContent} setImages={setImages} setIsImageUploading={setIsImageUploading} theme="snow" {...props} data-sentry-element="QuillNoSSRWrapper" data-sentry-source-file="WrappedEditor.tsx" />
    </>;
}
export default WrappedEditor;