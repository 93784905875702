import React, { ReactNode } from 'react';
import Img from '@/components/common/Img';
interface Props {
  className?: string;
  text?: ReactNode;
  style?: React.CSSProperties;
}
function Loading({
  className,
  text = '설탕노움이 열심히 페이지를 만들고 있습니다',
  style
}: Props) {
  return <div className={`flex min-h-screen min-w-full flex-col items-center justify-center gap-4 p-4 ${className}`} style={style} data-sentry-component="Loading" data-sentry-source-file="Loading.tsx">
      <Img className="h-16 w-16 object-contain" src="https://blog.kakaocdn.net/dn/bhfQqS/btrjbyMezrk/4KnsrBkEQKv7P7PT36jTb0/img.gif" data-sentry-element="Img" data-sentry-source-file="Loading.tsx" />
      {text && <div className="ckk font-light">{text}</div>}
    </div>;
}
export default Loading;