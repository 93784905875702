import React, { ReactNode } from 'react';
interface Props {
  icon: ReactNode;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  children?: ReactNode;
}
const CustomToolbarTab: React.FC<Props> = ({
  icon,
  selected,
  onClick,
  children
}) => {
  return <div className="flex cursor-pointer items-center justify-center" style={{
    border: '0.5px solid rgba(0,0,0,0.2)',
    background: selected ? 'white' : 'rgba(0,0,0,0.1)',
    borderBottom: '0.5px solid transparent',
    borderRadius: '10px 10px 0 0',
    padding: '5px 12px 2px'
  }} onClick={onClick} data-sentry-component="CustomToolbarTab" data-sentry-source-file="CookieToolbarTab.tsx">
      <div style={{
      marginRight: 5
    }}>{icon}</div>
      <div>{children}</div>
    </div>;
};
export default CustomToolbarTab;